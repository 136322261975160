import * as React from 'react';
import './AboutSection.scss';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FormattedMessage } from 'react-intl';
import { BsPersonSquare, BsFillEnvelopeFill, BsFillPinMapFill, BsLinkedin } from 'react-icons/bs';

export default function AboutSection({ id, profile }) {
  return (
    <section id={id} className="section about-section">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="about-left">
              <div className="about-header-img">
                <GatsbyImage
                  image={getImage(profile.photo)}
                  alt="My profile picture"
                  className="responsive-fluid"
                />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="section-header about-right">
              <h2>
                <FormattedMessage id="aboutTitle" />
              </h2>
              <h3>
                <FormattedMessage id="aboutSubTitle" />
              </h3>
              {renderRichText(profile.motivation)}
              <div className="row">
                <div className="col-md-10 col-sm-12">
                  <div className="about-details">
                    <ul>
                      <li>
                        <BsPersonSquare className="me-3" />
                        <span>{profile.fullName}</span>
                      </li>
                      <li>
                        <BsFillPinMapFill className="me-3" />
                        <span>{profile.area}</span>
                      </li>
                      <li>
                        <BsFillEnvelopeFill className="me-3" />

                        <a href={`mailto:${profile.email}`}>{profile.email}</a>
                      </li>
                      <li>
                        <BsLinkedin className="me-3" />
                        <a href={profile.linkedin} target="_blank" rel="noopener noreferrer">
                          <FormattedMessage id="aboutConnect" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
