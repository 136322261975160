import * as React from 'react';
import './FooterSection.scss';

export default function FooterSection({ copyright, author }) {
  return (
    <div className="container">
      <p>
        Website designed and maintained by <a href={author.url}>{author.name}</a>, reach out to{' '}
        <a href={`mailto: ${author.email}`}>{author.email}</a>.
      </p>
      <div className="copyright">
        &copy; Copyright <strong>{copyright}</strong>. All Rights Reserved
      </div>
    </div>
  );
}
