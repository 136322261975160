import * as React from 'react';
import './index.scss';
import { graphql } from 'gatsby';
import { useIntl } from 'react-intl';
import NavBar from '../components/navigation/NavBar';
import HeroSection from '../components/sections/hero/HeroSection';
import AboutSection from '../components/sections/about/AboutSection';
import ExperienceSection from '../components/sections/experience/ExperienceSection';
import ContactSection from '../components/sections/contact/ContactSection';
import FooterSection from '../components/sections/footer/FooterSection';

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
        author {
          name
          url
          email
        }
      }
    }
    profiles: allContentfulProfile {
      nodes {
        locale: node_locale
        fullName
        tagline
        motivation {
          raw
        }
        area
        email
        linkedin
        photo {
          gatsbyImageData
        }
      }
    }
    experience: allContentfulWorkExperience(sort: { fields: [stopped], order: DESC }) {
      nodes {
        id
        locale: node_locale
        type
        title
        description {
          raw
        }
        shortDescription
        started
        stopped
        company {
          name
          location
        }
      }
    }
    opengraphAsset: contentfulAsset(filename: { eq: "opengraph.png" }) {
      publicUrl
      width
      height
    }
  }
`;

function HomePage({ data, pageContext }) {
  const { locale } = pageContext;
  const { siteMetadata } = data.site;
  const profile = data.profiles.nodes.find((p) => p.locale === locale);
  const experience = data.experience.nodes.filter((w) => w.locale === locale);
  const intl = useIntl();

  return (
    <>
      <header>
        <NavBar
          items={[
            { text: intl.messages.navHome, id: 'hero' },
            { text: intl.messages.navAbout, id: 'about' },
            { text: intl.messages.navResume, id: 'resume' },
            { text: intl.messages.navContact, id: 'contact' },
          ]}
        />
      </header>

      <HeroSection
        id="hero"
        title={profile.fullName}
        subTitle={profile.tagline}
        resumeUrl={profile.resume?.url}
      />

      <main id="main">
        <AboutSection id="about" profile={profile} />
        <ExperienceSection id="resume" experience={experience} />
        <ContactSection id="contact" profile={profile} />
      </main>

      <footer id="footer">
        <FooterSection copyright={profile.fullName} author={siteMetadata.author} />
      </footer>
    </>
  );
}

export function Head({ data, pageContext }) {
  const { locale } = pageContext;
  const profile = data.profiles.nodes.find((p) => p.locale === locale);
  const { title, siteUrl } = data.site.siteMetadata;
  const { opengraphAsset } = data;

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={profile.tagline} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={profile.tagline} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:image" content={opengraphAsset.publicUrl} />
      <meta property="og:image:width" content={opengraphAsset.width} />
      <meta property="og:image:height" content={opengraphAsset.height} />

      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'Person',
          name: profile.fullName,
          url: siteUrl,
          image: opengraphAsset.publicUrl,
          sameAs: [profile.linkedin],
        })}
      </script>
    </>
  );
}

export default HomePage;
