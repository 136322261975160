import React, { useState, useEffect, useRef } from 'react';
import './NavBar.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import useEventListener from '../../hooks/useEventListener';
import LanguageSwitcher from './LanguageSwitcher';

export default function NavBar({ items = [] }) {
  const navBarRef = useRef();
  const [scrolled, setScrolled] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [readingProgress, setReadingProgress] = useState(0);

  const updateReadingProgress = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    setReadingProgress(Math.round((winScroll / height) * 1000) / 10);
  };

  const onScroll = () => {
    setScrolled(navBarRef.current && window.pageYOffset > navBarRef.current.offsetHeight);
    updateReadingProgress();

    for (let i = 0; i < items.length; i += 1) {
      const element = document.getElementById(items[i].id);
      if (element && window.scrollY + window.innerHeight / 2 >= element.offsetTop) {
        setActiveItem(i);
      }
    }
  };

  useEventListener('scroll', onScroll);
  useEventListener('resize', updateReadingProgress);

  return (
    <Navbar ref={navBarRef} fixed="top" className={scrolled ? 'header-scrolled' : ''}>
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            {items.map(({ id, text }, index) => (
              <Nav.Link key={id} active={index === activeItem} href={`#${id}`}>
                {text}
              </Nav.Link>
            ))}
            <LanguageSwitcher />
          </Nav>
        </Navbar.Collapse>
        <progress value={readingProgress} max="100" />
      </Container>
    </Navbar>
  );
}
