import * as React from 'react';
import './ContactForm.scss';
import { FormattedMessage, useIntl } from 'react-intl';

export default function ContactForm({ action }) {
  const intl = useIntl();
  return (
    <form action={action} method="post" className="contact-form">
      <div className="row">
        <div className="col-md-6 form-group">
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            placeholder={intl.messages.contactFormPlaceholderName}
            required
          />
        </div>
        <div className="col-md-6 form-group mt-3 mt-md-0">
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder={intl.messages.contactFormPlaceholderEmail}
            required
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <input
          type="text"
          className="form-control"
          name="subject"
          id="subject"
          placeholder={intl.messages.contactFormPlaceholderSubject}
          required
        />
      </div>
      <div className="form-group mt-3">
        <textarea
          className="form-control"
          name="message"
          rows="6"
          placeholder={intl.messages.contactFormPlaceholderMessage}
          required
        />
      </div>
      <div className="text-center mt-3">
        <button className="btn btn-dark" type="submit">
          <FormattedMessage id="contactFormSubmit" />
        </button>
      </div>
    </form>
  );
}
