import { useEffect, useRef } from 'react';

const useEventListener = (eventName, handler) => {
  const handlerReference = useRef(handler);

  useEffect(() => {
    handlerReference.current = handler;
  });

  useEffect(() => {
    const callback = (e) => handlerReference.current(e);
    window.addEventListener(eventName, callback);
    return () => {
      window.removeEventListener(eventName, callback);
    };
  }, [eventName]);
};

export default useEventListener;
