import * as React from 'react';
import './ExperienceSection.scss';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FormattedMessage } from 'react-intl';

export default class ExperienceSection extends React.PureComponent {
  static renderResumeItem({ id, title, description, shortDescription, company, started, stopped }) {
    const startedYear = new Date(started).getFullYear();
    let stoppedYear;
    if (stopped) {
      stoppedYear = new Date(stopped).getFullYear();
    }

    let dateRange = `${startedYear}`;
    if (stoppedYear && stoppedYear !== startedYear) {
      dateRange += ` - ${stoppedYear}`;
    }

    return (
      <div key={id} className="resume-item">
        <h4>{title}</h4>
        <h5>{`${company.name} (${dateRange})`}</h5>
        {shortDescription && <h6>{shortDescription}</h6>}
        {renderRichText(description)}
      </div>
    );
  }

  static renderResumeArea(experiences = [], minColumnSize = 2) {
    if (!Array.isArray(experiences)) {
      return null;
    }

    const columns = [];

    if (experiences.length < minColumnSize * 2) {
      columns.push(experiences);
    } else {
      const index = Math.ceil(experiences.length / 2);
      columns.push(experiences.slice(0, index));
      columns.push(experiences.slice(index, experiences.length));
    }

    return columns.map((column) => (
      <div key={Math.random()} className="col-md-6">
        {column.map((experience) => ExperienceSection.renderResumeItem(experience))}
      </div>
    ));
  }

  render() {
    const { id, experience = [] } = this.props;

    const workExperience = experience.filter(({ type }) => type === 'work');
    const education = experience.filter(({ type }) => type === 'education');

    return (
      <section id={id} className="section">
        <div className="container">
          <div className="section-header text-center">
            <h2>
              <FormattedMessage id="experienceTitle" />
            </h2>
          </div>

          {workExperience.length > 0 && (
            <div className="row">
              <h3 className="resume-title">
                <FormattedMessage id="experienceHeadingWork" />
              </h3>
              {ExperienceSection.renderResumeArea(
                workExperience.filter(({ type }) => type === 'work')
              )}
            </div>
          )}

          {education.length > 0 && (
            <div className="row mt-5">
              <h3 className="resume-title">
                <FormattedMessage id="experienceHeadingEducation" />
              </h3>
              {ExperienceSection.renderResumeArea(
                education.filter(({ type }) => type === 'education'),
                1
              )}
            </div>
          )}
        </div>
      </section>
    );
  }
}
