import * as React from 'react';
import './ContactSection.scss';
import { FormattedMessage } from 'react-intl';
import ContactForm from './ContactForm';

export default function ContactSection({ id }) {
  return (
    <section id={id} className="section contact">
      <div className="container">
        <div className="section-header text-center">
          <h2>
            <FormattedMessage id="contactTitle" />
          </h2>
          <h3>
            <FormattedMessage id="contactSubTitle" />
          </h3>
        </div>

        <div className="row">
          <div className="col-12">
            <ContactForm action={`https://formspree.io/f/${process.env.GATSBY_FORMSPREE_TOKEN}`} />
          </div>
        </div>
      </div>
    </section>
  );
}
