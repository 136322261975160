import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useI18nL10nContext } from 'gatsby-plugin-i18n-l10n';
import { Link } from 'gatsby';

function IconFlagNL() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 90 60">
      <rect fill="#21468B" width="90" height="60" />
      <rect fill="#FFF" width="90" height="40" />
      <rect fill="#AE1C28" width="90" height="20" />
    </svg>
  );
}

function IconFlagEN() {
  const stroke = { strokeWidth: '1pt' };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 90 60">
      <rect style={{ ...stroke, fill: 'rgb(0, 0, 102)' }} height="60" width="90" />
      <path
        style={{ ...stroke, fill: 'rgb(255, 255, 255)' }}
        d="M 0 0 L 0 6.72 L 80 60 L 90 60 L 90 53 L 10 0 L 0 0 Z M 90 0 L 90 6.72 L 10 60 L 0 60 L 0 53 L 80 0 L 90 0 Z"
      />
      <path
        style={{ ...stroke, fill: 'rgb(255, 255, 255)' }}
        d="M 38 0 L 38 60 L 53 60 L 53 0 L 38 0 Z M 0 20 L 0 40 L 90 40 L 90 20 L 0 20 Z"
      />
      <path
        style={{ ...stroke, fill: 'rgb(204, 0, 0)' }}
        d="M 0 24 L 0 36 L 90 36 L 90 24 L 0 24 Z M 40 0 L 40 60 L 49.484 60 L 49.484 0 L 40 0 Z"
      />
      <path
        style={{ ...stroke, fill: 'rgb(204, 0, 0)' }}
        d="M 0 60 L 30 40 L 37 40 L 7 60 L 0 60 Z M 0 0 L 30 20 L 23 20 L 0 4.5 L 0 0 Z M 53 20 L 83 0 L 90 0 L 60 20 L 53 20 Z M 90 60 L 60 40 L 67 40 L 90 55.5 L 90 60 Z"
      />
    </svg>
  );
}

const flagIcons = {
  'nl-NL': <IconFlagNL />,
  'en-GB': <IconFlagEN />,
};

function LanguageSwitcherButton({ locale, path }) {
  return (
    <Link className="nav-link" to={path}>
      <FormattedMessage id="heroChangeLanguage" />
      <span className="ms-3">{flagIcons[locale]}</span>
      <span className="visually-hidden">
        <FormattedMessage id="heroChangeLanguageSR" />
      </span>
    </Link>
  );
}

function LanguageSwitcher() {
  const { translations } = useI18nL10nContext();

  return translations.map(({ locale, path }) => (
    <LanguageSwitcherButton key={locale} locale={locale} path={path} />
  ));
}

export default LanguageSwitcher;
